<template>
  <main class="mx-auto">
    <section class="row">
      <div class="tabs">
        <div class="tab border">
          <div class="relative">
            <input type="checkbox" class="w-full absolute cursor-pointer opacity-0 h-14">
            <header class="cursor-pointer select-none tab-label mr-2">
              <div class="row pt-4 pl-4 text-sm">
                <span v-if="year == yearSelected" class="mr-2 font-bold">ปีการศึกษา {{ year }}</span>
                <span v-else class="mr-2">ปีการศึกษา {{ year }}</span>
                <div class="arrow">
                  <svg itemid="" aria-hidden="true" class="my-auto" data-reactid="266" fill="none" height="20" width="24" stroke="#606F7B" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><polyline points="6 9 12 15 18 9"></polyline></svg>
                </div>
              </div>
            </header>
            <div class="tab-content text-sm">
              <hr>
              <div>
                <ul class="pl-8">
                  <li v-for="(course, index) in labels" :key="course.id" class="cursor-pointer" @click="passEvent(course)">
                      <p v-if="index === 0 && course[1] == typeSelected && year == yearSelected && course[2] == semesterSelected && course[3] == prevCourse" class="-pt-2 pb-4 font-bold">{{ labels[index][0] }}</p>
                      <p v-else-if="index === 0" class="-pt-2 pb-4">{{ labels[index][0] }}</p>
                      <p v-else-if="index !== 0 && course[1] == typeSelected && year == yearSelected && course[2] == semesterSelected && course[3] == prevCourse" class="-pt-2 pb-4 font-bold">{{ labels[index][0] }}</p>
                      <p v-else class="pb-4">{{ labels[index][0] }}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "Select",
  props: ["data", "typeSelected", "yearSelected", "semesterSelected", "prevCourse"],
  data() {
    return {
      year: null,
      courses: null,
      labels: [],
    }
  },
  async created() {
    this.year = Object.keys(this.data)[0]
    this.courses = this.data[Object.keys(this.data)[0]]
    let label = []
    for (let course in this.courses) {
      if (this.courses[course]['type'] == 'PJ' && this.courses[course]['prev_course'] == null && label.includes('โครงงาน 1') == false) {
        this.labels.push(['โครงงาน 1', 'PJ', null, null, 1])
        label.push('โครงงาน 1')
      } else if (this.courses[course]['type'] == 'PJ' && this.courses[course]['prev_course'] != null && label.includes('โครงงาน 2') == false) {
        this.labels.push(['โครงงาน 2', 'PJ', null, '', 2])
        label.push('โครงงาน 2')
      } else if (this.courses[course]['type'] == 'CP' && label.includes('สหกิจศึกษา') == false) {
        this.labels.push(['สหกิจศึกษา', 'CP', null, null, 3])
        label.push('สหกิจศึกษา')
      } else if (this.courses[course]['type'] == 'IS' && this.courses[course]['type_is'] == '1' && label.includes('ศึกษาอิสระ 1') == false) {
        this.labels.push(['ศึกษาอิสระ 1', 'IS', 1, null, 4])
        label.push('ศึกษาอิสระ 1')
      } else if (this.courses[course]['type'] == 'IS' && this.courses[course]['type_is'] == '2' && label.includes('ศึกษาอิสระ 2') == false) {
        this.labels.push(['ศึกษาอิสระ 2', 'IS', 2, null, 5])
        label.push('ศึกษาอิสระ 2')
      }
    }
    this.labels.sort(function(l1, l2) { return l1[4] - l2[4]})
  },
  methods: {
    passEvent(course) {
      this.$emit('changeSelected', this.year, course[1], course[2], course[3])
    }
  }
}
</script>

<style>
.tab {
  overflow: hidden;
}

.tab-content {
  max-height: 0;
  transition: all 0.2s;
}

input:checked + .tab-label .arrow svg {
  transform: rotate(180deg);
}

input:checked + .tab-label::after {
  transform: rotate(90deg);
}

input:checked ~ .tab-content {
  max-height: 100vh;
}
</style>
