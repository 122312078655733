<template>
  <div class="main">
    <NavBar />
    <!-- Loading -->
    <div v-if="loading" class="content">
      <div class="loader m-auto"></div>
    </div>

    <!-- Content -->
    <div v-else class="content-width">
      <!-- Course -->
      <div class="col-span-1">
        <Select
          v-bind:data="data"
          v-bind:typeSelected="this.selected.type"
          v-bind:yearSelected="this.selected.year"
          v-bind:semesterSelected="this.selected.semester"
          v-bind:prevCourse="this.selected.prev"
          v-for="data in yearCourses"
          :key="data.id"
          @changeSelected="changeSelected"
          @click="changeSelectedCourse"
        />
      </div>
      
      <div class="col-span-7 ml-8">
        <!-- Filter -->
        <div class="grid grid-cols-9 gap-y-2">
          <!-- Header -->
          <div class="col-span-9 table-header mb-4">{{ filtered.title }}</div>
          
          <!-- Download file (.zip) -->
          <div v-if="selected.course.year >= 2565" class="col-span-9 row">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 my-auto" fill="none" viewBox="0 0 24 24" stroke="black"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"/></svg>
            <button @click="downloadZip()" class="file-button ml-4">{{ filtered.title }} [ทั้งหมด]</button>
          </div>

          <!-- Program -->
          <label class="my-auto">หลักสูตร</label>
          <select v-model="filtered.code" @change="changeSelectedCourseByCode" class="col-span-8 select-bar-free">
            <option v-for="program in programs" :key="program.id" :value="program.code">{{ program.name }}</option>
          </select>
         
          <!-- Form -->
          <label class="my-auto">เอกสาร</label>
          <select v-model="filtered.form" @change="filterProject" :disabled="disabledFormFilter" class="col-span-8 select-bar-free">
            <option v-for="form in forms" :key="form.id" :value="form.id">{{ form.form_entry_detail.title }}</option>
          </select>
          
          <!-- Status -->
          <label class="my-auto">สถานะ</label>
          <select v-model="filtered.status" @change="filterProject" class="col-span-2 select-bar-free">
            <option value="P">ส่งแล้ว</option>
            <option value="W">รอการตรวจ</option>
            <option value="F">ไม่พบข้อมูล</option>
          </select>
          
          <!-- Start Date -->
          <label class="my-auto">ระยะเวลาส่ง</label>
          <input v-model="filtered.start" @change="filterProject" type="date" class="col-span-2 select-bar-free" />
          
          <!-- End Date -->
          <label class="my-auto mx-10">ถึง</label>
          <input v-model="filtered.end" @change="filterProject" type="date" class="col-span-2 select-bar-free" />
        </div>

        <!-- Table loading -->
        <div v-if="tableLoading" class="mt-8">
          <div class="loader m-auto"></div>
        </div>
        <!-- Table -->
        <div v-else-if="filtered.projects.length > 0">
          <table>
            <thead>
              <tr>
                <th class="w-1/12">ลำดับ</th>
                <th class="w-5/12">หัวข้อโครงงาน</th>
                <th class="w-2/12">{{ filtered.title }}</th>
                <th class="w-4/12">วันที่ส่งเอกสาร</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <!-- Horizontal Line -->
              <tr><td colspan="5"><hr></td></tr>
              <!-- Data -->
              <tr v-for="(project, projectIndex) in filtered.projects" :key="projectIndex">
                <!-- Index -->
                <td>
                  <div>{{ projectIndex + 1 }}</div>
                </td>
                <!-- Topic -->
                <td class="text-left">
                  <div>{{ project.topic_th }}<br>({{ project.topic_en }})</div>
                </td>
                <!-- View -->
                <td>
                  <div v-if="project.documents.length == 0 || filtered.status == 'W' || filtered.status == 'F'">ไม่พบข้อมูล</div>
                  <div v-else v-for="(document, index) in project.documents" :key="index" class="h-12 my-1">
                    <div v-if="document.name == filtered.title && selected.course.year >= 2565">
                      <a :href="document.path" target="_blank">
                        <button class="view-button mx-2" style="background-color: #FFC700;">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto" fill="none" viewBox="0 0 16 16" stroke="white"><path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/><path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/></svg>
                        </button>
                      </a>
                    </div>
                    <div v-else-if="document.name == filtered.title && selected.course.year < 2565">
                      <a :href="document.path" target="_blank">
                        <button class="view-button mx-2" style="background-color: #FFC700;">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto" fill="none" viewBox="0 0 16 16" stroke="white"><path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/><path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/></svg>
                        </button>
                      </a>
                    </div>
                    <div v-else>ไม่พบข้อมูล</div>
                  </div>
                </td>
                <!-- Data -->
                <td class="text-center">
                  <div v-if="project.documents.length == 0 || filtered.status == 'W' || filtered.status == 'F'">ไม่พบข้อมูล</div>
                  <div v-else v-for="(document, index) in project.documents" :key="index" class="h-12 my-1">
                    <div v-if="document.name == filtered.title & selected.course.year >= 2565" class="h-10 ml-2">{{ dateFormat(document.checked) }}</div>
                    <div v-else class="h-10 ml-2">ไม่พบข้อมูล</div>
                  </div>
                </td>
                <!-- View -->
                <td>
                  <div v-if="selected.course.year >= 2565" class="h-12 my-1">
                    <router-link :to="'/project/' + project.id + '/'">
                      <button class="view-button mx-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto" viewBox="0 0 20 20" fill="white"><path fill-rule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clip-rule="evenodd" /></svg>
                      </button>
                    </router-link>
                  </div>
                  <div v-else-if="selected.course.year < 2565" class="h-12 my-1">
                    <router-link :to="'/project/' + project.id + '/'">
                      <button class="view-button mx-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto" viewBox="0 0 20 20" fill="white"><path fill-rule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clip-rule="evenodd" /></svg>
                      </button>
                    </router-link>
                  </div>
                  <div v-else></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Not Found -->
        <div v-else class="text-not-found">ไม่พบข้อมูลที่สอดคล้องกับตัวกรองข้อมูลที่เลือก</div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar"
import Select from "@/components/Select"
import moment from "moment"
import { yearCourseSelect } from "../mixins/other"

export default {
  name: "StatusManage",
  components: {
    NavBar,
    Select,
  },
  data() {
    return {
      loading: true,
      tableLoading: true,
      disabledFormFilter: false,
      courses: null,
      course: null,
      projects: null,
      years: [],
      yearCourses: [],
      selected: {},
      filtered: {},
      role: null,
    }
  },
  async created() {
    document.title = "ตรวจสอบเอกสาร | Carbon"
    this.role = this.$store.getters.payload.role
    // Permission
    if (this.role !== "STF") {
      this.$router.push("/")
    }
    await this.setSelected()
    let { years, yearCourses } = yearCourseSelect(this.courses)
    this.years = years
    this.yearCourses = yearCourses
    await this.changeSelectedCourse()
    this.loading = false
    this.tableLoading = false
  },
  methods: {
    translatedName(code) {
      switch (code) {
        case 'IT':
          return "เทคโนโลยีสารสนเทศ"
        case 'DSBA':
          return "วิทยาการข้อมูลและการวิเคราะห์เชิงธุรกิจ"
        case 'BIT':
          return "เทคโนโลยีสารสนเทศทางธุรกิจ (หลักสูตรนานาชาติ)"
        case 'DTI':
          return "เทคโนโลยีและนวัตกรรมข้อมูล"
        case 'IST':
          return "เทคโนโลยีระบบสารสนเทศ"
        case 'ITM':
          return "เทคโนโลยีสารสนเทศ และการจัดการ"
        case 'NST':
          return "เทคโนโลยีเครือข่ายและระบบ"
        default:
          return ""
      }
    },
    async setSelected() {
      this.courses = await this.$store.dispatch("getCourse")
      this.courses.sort(function (c1, c2) { return c1.code - c2.code })
      this.courses.sort(function (c1, c2) { return c2.year - c1.year })
      this.selected.course = this.courses[0]
      this.selected.year = this.selected.course.year
      this.selected.type = this.selected.course.type
      this.selected.prev = null
      this.selected.semester = (this.courses[0].type == 'PJ') ? null : this.selected.course.semester
      this.filtered.code = this.selected.course.program_code
    },
    changeSelected(year, type, semester, prev) {
      this.selected.year = year
      this.selected.type = type
      this.selected.prev = prev
      this.selected.semester = semester
    },
    async changeSelectedCourse() {
      let selectedCourses = []
      this.yearCourses.filter((yearCourse) => {
        if (this.selected.year in yearCourse) {
          let courses = yearCourse[this.selected.year]
          courses.filter((course) => {
            if (this.selected.type == course.type) {
              if (this.selected.type == 'CP') {
                selectedCourses.push(course)
              } else if (this.selected.type == 'IS' && this.selected.semester == course.semester) {
                selectedCourses.push(course)
              } else if (this.selected.type == 'PJ' && (this.selected.prev == course.prev_course || (this.selected.prev != null && course.prev_course != null)) ) {
                selectedCourses.push(course)
              }
            }
          })
        }
      })
      this.selected.courses = selectedCourses
      this.selected.course = selectedCourses[0]
      this.filtered.code = this.selected.course.program_code
      await this.setPrograms()
    },
    async changeSelectedCourseByCode() {
      this.selected.courses.filter((course) => {
        if (this.filtered.code == course.program_code) {
          this.selected.course = course
        }
      })
      await this.setPrograms()
    },
    async setPrograms() {
      let courseId = this.selected.course.id
      let programs = await this.$store.dispatch("getProgramByCourseId", { courseId: courseId })
      let translatedPrograms = []
      programs.filter((program) => {
        let translatedProgram = {
          id: program.id,
          code: program.code,
          name: this.translatedName(program.code)
        }
        translatedPrograms.push(translatedProgram)
      })
      this.programs = translatedPrograms
      await this.setFiltered()
    },
    async setFiltered() {
      let forms = []
      if (this.selected.course.year >= 2565) {
        forms = await this.$store.dispatch("getFormByCourseId", { id: this.selected.course.id, type: "filter" })
      }
      else {
        let formTitleList = ["รายงานความก้าวหน้า 1", "รายงานความก้าวหน้า 2", "รายงานโครงงาน 1", "รายงานโครงงาน 2", "รายงานฉบับย่อ", "โปสเตอร์", "เอกสารประกอบการนำเสนอ", "วิดีโอนำเสนอ"]
        let formNameList = ["mid_1", "mid_2", "doc_1", "doc_2", "paper_1", "poster_1", "slide_1", "vdo"]
        let projects1 = [0, 2]
        let projects2 = [1, 3, 4, 5, 6, 7]
        for (let i = 0; i < formTitleList.length; i++) {
          // IF: PROJECT 1
          if (this.selected.course.type == 'PJ' && this.selected.course.prev_course == null && projects1.includes(i)) {
            forms.push({ id: i, form_entry_detail: { title: formTitleList[i] }, name: formNameList[i] })
          }
          // IF: PROJECT 2
          else if (this.selected.course.type == 'PJ' && this.selected.course.prev_course != null && projects2.includes(i)) {
            forms.push({ id: i, form_entry_detail: { title: formTitleList[i] }, name: formNameList[i] })
          }
          else if (this.selected.course.type != 'PJ') {
            forms.push({ id: i, form_entry_detail: { title: formTitleList[i] }, name: formNameList[i] })
          }
        }
      }
      if (forms.length == 0) {
        forms.push({ id: null, form_entry_detail: { title: 'ไม่มี' }, name: null })
        this.disabledFormFilter = true
      } else {
        this.disabledFormFilter = false
      }
      this.forms = forms
      this.filtered.form = forms[0].id
      this.filtered.title = forms[0].form_entry_detail.title
      // Reset filter
      this.filtered.status = "P"
      this.filtered.start = null
      this.filtered.end = null
      await this.filterProject()
    },
    async filterProject() {
      this.tableLoading = true
      if (this.selected.course) {
        let formId = this.filtered.form
        let status = this.filtered.status
        let start = this.filtered.start
        let end = this.filtered.end
        let projects = await this.$store.dispatch("getFilteredProjectDocument", { course: this.selected.course.id, form: formId, status: status, start: start, end: end })
        this.filtered.projects = projects
        // Change title
        this.forms.filter((form) => {
          if (form.id === formId) {
            this.filtered.title = form.form_entry_detail.title
          }
        })
      } else {
        this.filtered.projects = []
      }
      this.tableLoading = false
    },
    dateFormat(datetime) {
      return moment(new Date(datetime)).locale("th").format('LLLL')
    },
    async downloadZip() {
      if (this.filtered.projects.length == 0) {
        alert("ไม่พบข้อมูลที่สอดคล้องกับข้อมูลที่เลือกภายในระบบ")
      } else if (this.filtered.status !== 'P') {
        alert("ดาวน์โหลดได้เฉพาะข้อมูลที่ผ่านการตรวจสอบจากอาจารย์ที่ปรึกษาแล้วเท่านั้น")
      } else {
        await this.$store.dispatch("downloadDocumentFile", { name: this.filtered.title + ".zip", type: "zip", formData_id: this.filtered.projects[0].documents[0].id })
        .catch((err) => { if (err == 400) { alert("ไม่สามารถดาวน์โหลดวิดีโอได้") }})
      }
    }
  }
}
</script>
